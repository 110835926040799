import { AnyObject, IConfiguration } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const SHIPPINGS_COMPONENT_KEY = 'Shippings'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultConfig: AnyObject = {
  template: {
    withPlus: true
  },
  displayCarrierTitle: false
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const getDriverCallbacks = (config: IConfiguration) => {
  const componentsConfig = config.byKey<AnyObject>('components')

  if (!componentsConfig) {
    return []
  }

  if (
    !componentsConfig.structure ||
    !componentsConfig.structure.payments ||
    !componentsConfig.structure.payments.driver ||
    !componentsConfig.structure.payments.callbacks
  ) {
    return []
  }

  return componentsConfig.structure.payments.driver.callbacks
}
