





























import { AnyObject } from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'

import { logger } from '../../../../../support'

import { PaymentBaseDriver } from './BaseDriver.vue'

/**
 * Driver for `przelewy24_blik`
 *
 * TODO: This driver is temporarily disabled
 *
 * @author Javlon Khalimjonov (javlon.khalimjonov@movecloser.pl)
 */
@Component({ name: 'Przelewy24Blik' })
export class Przelewy24Blik extends PaymentBaseDriver {
  public code: string = ''
  public isPending: boolean = false

  /**
   * @override
   */
  protected selectCandidate () {
    //
  }

  public transactionData: AnyObject | null = null

  /**
   * Registers transaction
   */
  private async registerBlikTransaction (): Promise<void> {
    try {
      this.isPending = true

      this.transactionData = await this.checkoutService.registerPrzelewy24BlikTransaction({
        cartId: this.cart.id,
        saveAlias: true
      })
    } catch (e) {
      this.innerError = this.$t('front.checkout.organisms.PaymentStep.drivers.Przelewy24Blik.transaction.error').toString()
      this.resolveCandidate(false)
      logger(e, 'error')
    } finally {
      this.isPending = false
    }
  }

  private async onModelUpdate (value: string): Promise<void> {
    if (value.length <= 6) {
      this.code = value
    }

    if (value.length > 5) {
      await this.registerBlikTransaction()
      try {
        if (this.transactionData) {
          await this.setPaymentMethod([this.cart.id, { code: this.method.id }], {
            type: this.method.id,
            data: {
              blikCode: this.code,
              regulationAccept: false,
              token: this.transactionData.token,
              sessionId: this.transactionData.transaction
            }
          })
        }
      } catch (e) {
        this.resolveCandidate(false)
        logger(e, 'error')
      }
    }
  }
}
export default Przelewy24Blik
