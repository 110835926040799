/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const SHIPPING_STEP_COMPONENT_KEY = 'ShippingStepComponent'

export interface ShippingStepConfig {
  buttonGoNextTheme: string
  buttonIcon: string | null
  icons: unknown
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultConfig: ShippingStepConfig = {
  buttonGoNextTheme: 'secondary',
  buttonIcon: null,
  icons: {}
}
