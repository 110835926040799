












import { Component } from 'vue-property-decorator'

import { PaymentMethodPayload } from '../../../../contexts'

import { AbstractStepPreview } from '../AbstractStepPreview'
import { paymentIcon } from './PaymentStep.helpers'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'PaymentStepPreview'
})
export class PaymentStepPreview extends AbstractStepPreview {
  private method: PaymentMethodPayload | null = this.payload?.payment ?? null

  public get icon (): string | null {
    if (!this.method) {
      return null
    }

    return paymentIcon[this.method.code]
  }

  public get name (): string | null {
    if (!this.method) {
      return null
    }

    return this.$t(
      `front.checkout.organisms.PaymentStep.method.${this.method.code}.title`).toString()
  }
}

export default PaymentStepPreview
