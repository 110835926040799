





























import { Component, Prop, PropSync } from 'vue-property-decorator'

import {
  AvailableShippingMethod,
  ShippingCarrierCode,
  ShippingMethod,
  ShippingMethodCode
} from '../../../../contexts'
import { COMPONENTS_STRUCTURE_CONFIG, Inject } from '../../../../support'
import StructureConfigurableMixin, {
  ComponentsStructureConfig
} from '../../../../support/mixins/StructureConfigurable.mixin.vue'

import { Loader } from '../../../shared/molecules/Loader'

import { CheckoutServiceType, ICheckoutService } from '../../services/checkout'
import { CartModel, CheckoutStepCallback } from '../../contracts'

import { defaultDriver, driversRegistry } from './drivers/drivers'
import {
  isFreeShipmentThresholdReached,
  shippingIcon,
  supportedShippingMethods
} from './Shippings.helpers'
import { defaultConfig, SHIPPINGS_COMPONENT_KEY } from './Shippings.config'
import { CART_SUMMARY_COMPONENT_KEY } from '../CartSummary/CartSummary.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Shippings>({
  name: 'Shippings',
  components: {
    Loader
  },
  mounted () {
    this.loadAvailableShippingMethods(this.cart.id)
  },
  created (): void {
    this.config = this.getComponentConfig(SHIPPINGS_COMPONENT_KEY, defaultConfig)
  }
})
export class Shippings extends StructureConfigurableMixin {
  @PropSync('errors', { type: Array, required: false, default: () => ([]) })
  public _errors!: string[]

  @PropSync('selected', { type: Object, required: false, default: null })
  public _selected!: ShippingMethod | null

  @PropSync('callbacks', { type: Array, required: true })
  public _callbacks!: CheckoutStepCallback[]

  @Prop({ type: Object, required: true })
  protected readonly cart!: CartModel

  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  public availableShippingMethods: AvailableShippingMethod[] = []

  public isLoading = true

  public get availableMethods (): string[] {
    return this.getConfigProperty<string[]>('drivers') ?? supportedShippingMethods
  }

  public get driversOrder (): Record<string, number> | undefined {
    return this.getConfigProperty('driversOrder')
  }

  public get methodsWhenFree (): Array<string> {
    return this.getConfigProperty('methodsWhenFree')
  }

  public async loadAvailableShippingMethods (cardId: string) {
    this.isLoading = true

    this.availableShippingMethods = await this.checkoutService.loadAvailableShippingMethods(
      cardId).finally(() => {
      this.isLoading = false
    })
  }

  public get hasFreeDelivery (): boolean {
    // TODO: Move to settings
    const componentsConfig = this.$configuration?.byFile(COMPONENTS_STRUCTURE_CONFIG) as ComponentsStructureConfig
    const freeDeliveryValue = componentsConfig.structure[CART_SUMMARY_COMPONENT_KEY].freeDeliveryFrom ?? this.cart.getFreeDeliveryFrom()

    return this.cart.getSubtotalPrice() >= freeDeliveryValue
  }

  public get methods () {
    const considerFreeShipment: boolean = isFreeShipmentThresholdReached(this.availableShippingMethods.map((method) => method.methodCode))
    let output = this.availableShippingMethods
      .filter(({ methodCode }) => this.availableMethods.includes(methodCode))
      .filter((method) => {
        return considerFreeShipment ? method.price.value === 0 : true
      })
      .map(({ carrierTitle, methodCode, price }: ShippingMethod) => ({
        data: {
          carrierCode: methodCode,
          carrierTitle,
          description: this.$t(`front.checkout.organisms.ShippingStep.method.${methodCode}.description`),
          image: shippingIcon[methodCode],
          price,
          title: this.$t(`front.checkout.organisms.ShippingStep.method.${methodCode}.title`)
        },
        driver: methodCode in driversRegistry ? driversRegistry[methodCode] : defaultDriver,
        id: methodCode
      }))

    if (this.methodsWhenFree.length > 0 && this.hasFreeDelivery) {
      output = output
        .filter((method) => {
          return this.methodsWhenFree.includes(method.id)
        })
    }

    if (this.driversOrder) {
      const allMethodsDefinedByOrder = output.every((element) => this.driversOrder![element.id])

      if (allMethodsDefinedByOrder) {
        output = output.sort((a, b) => this.driversOrder![a.id] > this.driversOrder![b.id] ? 1 : -1)
      } else {
        const definedElements = output.filter((element) => this.driversOrder![element.id])
        const undefinedElements = output.filter((element) => !this.driversOrder![element.id])

        output = definedElements.concat(undefinedElements)
      }
    }

    return output
  }

  public get selectedShippingCode (): ShippingCarrierCode | null {
    return this._selected?.methodCode ?? null
  }

  public set selectedShippingCode (value: ShippingMethodCode | null) {
    this._selected = this.availableShippingMethods.find(method => method.methodCode === value) ?? null
    this._errors = []
  }
}
export default Shippings
