
import { Component } from 'vue-property-decorator'

import { logger } from '../../../../../support'
import { PaymentMethodCode } from '../../../../../contexts'

import { CartMutationTypes } from '../../../contracts'

import PaymentBaseDriver from './BaseDriver.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({ name: 'Przelewy24Apple' })
export class Przelewy24Apple extends PaymentBaseDriver {
  /**
   * @override
   */
  protected async selectCandidate (): Promise<void> {
    this._callbacks = []
    try {
      this.$emit('saving', true)
      const cart = await this.checkoutService.setPaymentMethod(
        this.cart.id,
        { code: this.method.id as PaymentMethodCode },
        {
          type: this.method.id,
          data: {
            regulationAccept: false
          }
        }
      )

      this.$store.commit(CartMutationTypes.SetCart, cart)
      this.resolveCandidate(true)
    } catch (e) {
      logger(e, 'error')
      this.resolveCandidate(false)
    } finally {
      this.$emit('saving', false)
    }
  }
}
export default Przelewy24Apple
