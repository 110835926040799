









import { AnyObject } from '@movecloser/front-core'
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'

import { ICheckoutService } from '../../../services/checkout'
import { CartModel, CheckoutStepCallback } from '../../../contracts'

import { PaymentMethod } from '../PaymentStep.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'PaymentBaseDriver' })
export class PaymentBaseDriver extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly cart!: CartModel

  @Prop({ type: Object, required: true })
  protected readonly checkoutService!: ICheckoutService

  @PropSync('callbacks', { type: Array, required: true })
  protected _callbacks!: CheckoutStepCallback[]

  @Prop({ type: Boolean, required: true })
  protected readonly isCandidate!: boolean

  @Prop({ type: Object, required: true })
  public readonly method!: PaymentMethod

  @Prop({ type: Function, required: true })
  protected readonly resolveCandidate!: (result: boolean) => void

  @Prop({ type: String, required: false, default: null })
  protected readonly selected!: string | null

  protected innerError: string | null = null

  /**
   * Cancels selection of candidate
   */
  protected cancelCandidate (): void {
    // Do nothing
  }

  /**
   * On candidate selection
   */
  protected selectCandidate (): void {
    this._callbacks = [
      {
        callable: this.checkoutService.setPaymentMethod,
        onThis: this.checkoutService,
        args: [this.cart.id, { code: this.method.id }]
      }
    ]
    this.resolveCandidate(true)
  }

  /**
   * Sets new payment method
   */
  protected setPaymentMethod (args: unknown[], provider?: AnyObject): void {
    this._callbacks = [
      {
        callable: this.checkoutService.setPaymentMethod,
        onThis: this.checkoutService,
        args: [...args, provider]
      }
    ]

    this.resolveCandidate(true)
  }

  @Watch('selected')
  private onSelectedChange (value: string): void {
    if (value !== this.method.id) this.resolveCandidate(false)
  }

  @Watch('isCandidate')
  private onCandidateChange (value: boolean): void {
    value ? this.selectCandidate() : this.cancelCandidate()
  }
}
export default PaymentBaseDriver
