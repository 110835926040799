
import { Component } from 'vue-property-decorator'

import { PaymentBaseDriver } from './BaseDriver.vue'
import { PaymentDriver } from './drivers'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<CashOnDelivery>({
  name: 'CashOnDelivery',
  created () {
    if (this.selected === PaymentDriver.CashOnDelivery) {
      this.selectCandidate()
    }
  }
})
export class CashOnDelivery extends PaymentBaseDriver {

}

export default CashOnDelivery
