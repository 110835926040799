
import { Component } from 'vue-property-decorator'

import { logger } from '../../../../../support'

import PaymentBaseDriver from './BaseDriver.vue'
import { PaymentMethodCode } from '../../../../../contexts'
import { CartMutationTypes } from '../../../contracts'
import { AnyObject } from '@movecloser/front-core'

/**
 * TODO
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Przelewy24Google>({
  name: 'Przelewy24Google'
})
export class Przelewy24Google extends PaymentBaseDriver {
  protected transactionData: AnyObject | null = null

  /**
   * @override
   */
  protected async selectCandidate (): Promise<void> {
    this._callbacks = []
    try {
      this.$emit('saving', true)
      const cart = await this.checkoutService.setPaymentMethod(
        this.cart.id,
        { code: this.method.id as PaymentMethodCode },
        {
          type: this.method.id,
          data: {
            regulationAccept: false
          }
        }
      )

      this.$store.commit(CartMutationTypes.SetCart, cart)
      this.resolveCandidate(true)
    } catch (e) {
      logger(e, 'error')
      this.resolveCandidate(false)
    } finally {
      this.$emit('saving', false)
    }
  }

  // private async registerPrzelewy24GooglePayTransaction (): Promise<void> {
  //   try {
  //    this.transactionData = await this.checkoutService.registerPrzelewy24GooglePayTransaction({
  //      cartId: this.cart.id, tokenObject: this. })
  //   } catch (e) {
  //
  //   }
  // }
}
export default Przelewy24Google
