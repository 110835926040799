










































import { Component } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'

import { DeliveryTime } from '../../../../../contexts'
import { Inject } from '../../../../../support'
import { ModalSizeMap } from '../../../../../dsl/molecules/Modal'

import { Modals } from '../../../config'

import { BaseDriver } from './BaseDriver.vue'
import { InPostParcel } from '../../InPostModal/InPostModal.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'InPostParcelDriver'
})
export class InPostParcelDriver extends BaseDriver {
  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  protected deliveryTime: DeliveryTime = {
    deliveryFrom: '2',
    deliveryTo: '3'
  }

  public parcel: InPostParcel | null = null

  public get selectedParcel (): string {
    if (!this.parcel) {
      return this.cart.selectedShippingMethod?.displayInfo || ''
    }

    return `${this.parcel.name}, ${this.parcel.address.line1}, ${this.parcel.address.line2}`
  }

  public change (): void {
    this.modalConnector.open(
      Modals.InPostModal,
      {
        cart: this.cart,
        onSelection: this.onSelection,
        selected: this.selectedParcel.split(',')[0] || ''
      },
      { size: ModalSizeMap.XLarge }
    )
  }

  protected cancelCandidate (): void {
    this.modalConnector.close(Modals.InPostModal)
  }

  protected onSelection (parcel: InPostParcel): void {
    this.parcel = parcel

    if (!parcel.payment_available) {
      this.disabledPaymentMethods.push('cashondelivery')
    }

    this._callbacks = [
      {
        callable: this.checkoutService.setInPostParcel,
        onThis: this.checkoutService,
        args: [this.cart.id, parcel.name]
      },
      {
        callable: this.checkoutService.setShippingDetails,
        onThis: this.checkoutService,
        args: [
          this.cart.id,
          {
            deliveryTime: this.deliveryTime,
            disabledPaymentMethods: this.disabledPaymentMethods,
            displayInfo: this.selectedParcel
          }
        ]
      }
    ]

    this.resolveCandidate(true)
    this.cancelCandidate()
  }

  protected selectCandidate (): void {
    this.modalConnector.open(
      Modals.InPostModal,
      {
        cart: this.cart,
        onClose: () => this.resolveCandidate(false),
        onSelection: this.onSelection
      },
      { size: ModalSizeMap.XLarge }
    )
  }
}

export default InPostParcelDriver
