















import { Component } from 'vue-property-decorator'

import { AbstractStepPreview } from '../AbstractStepPreview'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'CustomerDetailsStepPreview'
})
export class CustomerDetailsStepPreview extends AbstractStepPreview {}

export default CustomerDetailsStepPreview
