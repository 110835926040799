/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const SHIPPINGS_COMPONENT_KEY = 'ShippingsComponent'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export interface ShippingsConfig {
  drivers?: string[]
  driversOrder?: Record<string, number>
  methodsWhenFree: Array<string>
}

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const defaultConfig: ShippingsConfig = {
  drivers: undefined,
  driversOrder: undefined,
  methodsWhenFree: []
}
