
















import { Component, Prop, Vue } from 'vue-property-decorator'

import { SidesLayoutColBackground, sidesLayoutColBackgroundRegistry } from './SidesLayout.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component({ name: 'SidesLayout' })
export class SidesLayout extends Vue {
  @Prop({ type: String, required: false })
  public readonly leftClass?: string

  @Prop({ type: String, required: false })
  public readonly leftColBackground?: string

  @Prop({ type: String, required: false })
  public readonly rightClass?: string

  @Prop({ type: String, required: false })
  public readonly extraClass?: string

  @Prop({ type: String, required: false })
  public readonly rightColBackground?: string

  /**
   * Determines background color for left slot.
   */
  public get leftColBg (): string {
    if (typeof this.leftColBackground === 'undefined' ||
      !(this.leftColBackground in sidesLayoutColBackgroundRegistry)) {
      return SidesLayoutColBackground.None
    }

    return sidesLayoutColBackgroundRegistry[this.leftColBackground]
  }

  /**
   * Determines background color for right slot.
   */
  public get rightColBg (): string {
    if (typeof this.rightColBackground === 'undefined' ||
      !(this.rightColBackground in sidesLayoutColBackgroundRegistry)) {
      return SidesLayoutColBackground.None
    }

    return sidesLayoutColBackgroundRegistry[this.rightColBackground]
  }
}
export default SidesLayout
