import { VueConstructor } from 'vue'

import { PaymentBaseDriver } from './BaseDriver.vue'
import { CashOnDelivery } from './CashOnDelivery.vue'
import { Przelewy24Blik } from './Przelewy24Blik.vue'
import { Przelewy24Card } from './Przelewy24Card.vue'
import { Przelewy24General } from './Przelewy24General.vue'
import { Przelewy24Google } from './Przelewy24Google.vue'
import { Przelewy24SingleMethod } from './Przelewy24SingleMethod.vue'
import { Przelewy24Apple } from './Przelewy24Apple.vue'
import { Przelewy24PayPo } from './Przelewy24PayPo.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultPaymentDriver: VueConstructor = PaymentBaseDriver

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum PaymentDriver {
  CashOnDelivery = 'cashondelivery',
  Przelewy24Blik = 'przelewy24_blik',
  Przelewy24Card = 'przelewy24_card',
  Przelewy24General = 'przelewy24',
  Przelewy24SingleMethod = 'przelewy24_single_method',
  Przelewy24Google = 'przelewy24_google_pay',
  Przelewy24Apple = 'przelewy24_apple_pay',
  Przelewy24PayPo = 'przelewy24_pay_po'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const paymentsDriversRegistry: Record<string, VueConstructor> = {
  [PaymentDriver.CashOnDelivery]: CashOnDelivery as VueConstructor,
  [PaymentDriver.Przelewy24General]: Przelewy24General as VueConstructor,
  [PaymentDriver.Przelewy24Blik]: Przelewy24Blik as VueConstructor,
  [PaymentDriver.Przelewy24Card]: Przelewy24Card as VueConstructor,
  [PaymentDriver.Przelewy24SingleMethod]: Przelewy24SingleMethod as VueConstructor,
  [PaymentDriver.Przelewy24Google]: Przelewy24Google as VueConstructor,
  [PaymentDriver.Przelewy24Apple]: Przelewy24Apple as VueConstructor,
  [PaymentDriver.Przelewy24PayPo]: Przelewy24PayPo as VueConstructor
}
